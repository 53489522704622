.articleWrapper {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    gap: 80px;
}


.articleWrapper a {
    color: #457B9D;
  }

.articleTop h2,
.articleBottom h2 {
    font-weight: 500;
    margin-bottom: 24px;
    font-size: 30px;
    color: #1D3557;
    text-align: left;
    margin-top: 40px;
}

.articleTop p,
.articleBottom p {
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    text-align: left;
    line-height: 32px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1280px) {
    .articleWrapper {
        width: 95%;
        padding: 40px 30px;
    }

    .articleLeft h2,
    .articleRight h2 {
        font-size: 18px;
    }

    .articleLeft p,
    .articleRight p {
        font-size: 12px;
        line-height: 22px;
    }

    .articleWrapper {
        gap: 20px;
    }

    .articleTop,
    .articleBottom {
        gap: 40px;
    }
}

@media screen and (max-width: 600px) {

    .articleWrapper {
        width: 95%;
        padding: 10px 0;
        gap: 20px;
    }

    .articleLeft h2,
    .articleRight h2 {
        font-size: 18px;
    }

    .articleLeft p,
    .articleRight p {
        font-size: 15px;
        line-height: 22px;
    }

    .articleRight {
        display: none;
    }

}
#article-list {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    gap: 80px;
}

.article-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.article-list-container a {
    color: #457B9D;
}

.article-post {
    margin-bottom: 20px;
    flex: 1 1 200px;
}

@media screen and (max-width: 1280px) {
    #article-list {
        width: 95%;
        padding: 10px 24px;
    }
}

/* Adjustments for small screens */
@media screen and (max-width: 600px) {
    .article-list-container {
        display: block;
    }

    #article-list {
        padding: 40px 0px;
    }

    .article-post {
        flex: none;
    }
}
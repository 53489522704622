#started {
  background: #5D9CB8;
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
}

#started>div h1 {
  padding-left: 20%;
  padding-right: 20%;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
}

h1 span {
  color: #1E3348;
}

@media screen and (max-width: 550px) {
  #started>div h1 {
    padding-left: 5%;
    padding-right: 5%;
  }
}
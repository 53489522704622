.hero {
  background: "#FFF";
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.firstHome {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  position: relative;
}

.firstHome::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
  margin-top: 46px;
  margin-bottom: 46px;
  width: 2px;
  background-color: #ccc;
}

.heroContentContainer,
.signupFormContainer {
  flex: 1;
}

.heroContentContainer,
.signupFormContainer {
  padding-bottom: 10px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.hero_content h1 {
  font-size: 28px;
  margin-top: 12px;
  font-weight: 500;
}

.hero_content h1 span {
  font-weight: 700;
}

.hero h3 {
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.email-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  margin-top: 10px;
  border: 1px solid #979797;
  border-radius: 0px;
}

.email-input:focus {
  outline: none;
  border: 1px solid #979797;
  box-shadow: 0 0 0 1px rgba(0, 123, 255, 0.25);
}

.signup {
  background: #fff;
  width: 376px;
  margin: 25px auto;
  text-align: left;
  padding: 20px 0px;
}

.hero_content {
  width: 376px;
  margin: 25px auto;
}

#statusTitle {
  margin-bottom: 8px;
}

#status {
  margin-bottom: 8px;
}

.action-btn {
  background-color: #FA8401;
  color: #fff !important;
  text-align: center;
  box-sizing: border-box;
  box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
    inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
    inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 12px;
  padding: 16px;
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}


.signup-notice {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  margin: auto;
}

.hero a {
  color: #457B9D;
}

.result-chips {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.chip {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 20px;
  color: black;
  font-size: 12px;
  cursor: default;
  max-width: 240px;
  overflow: visible;
  cursor: pointer;
}

.chip .full-email {
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
  white-space: nowrap;
  padding: 3px 10px;
  background-color: black;
  color: white;
  border-radius: 3px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  z-index: 1000;
  transition-delay: 0s;
}

.chip:hover .full-email {
  display: block;
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(5px);
  pointer-events: auto;
  transition-delay: 1s;
}


.chip button {
  margin-left: 10px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.chip span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(100% - 30px);
}

.chip.valid {
  border-width: 1px;
  border-color: rgb(0, 128, 0);
}

.chip.invalid {
  border-width: 1px;
  border-color: red;
}

.chip.catchall {
  border-width: 1px;
  border-color: orange;
}

.chip button.remove-btn {
  flex-shrink: 0;
  flex-grow: 0;
  /* Prevents the button from growing */
  width: 18px;
  height: 18px;
  padding: 0;
  margin-left: 5px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1100px) {
  .firstHome {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
}

@media screen and (max-width: 768px) {

  .heroContentContainer,
  .signupFormContainer {
    padding-bottom: 0px;
    width: 100%;
    flex: none;
  }

  .hero_content {
    padding: 0 5%;
  }

  .firstHome::before {
    display: none;
  }

  .hero_content {
    width: 95%;
    margin-bottom: 20px;
  }

  .signup {
    width: 95%;
    padding: 10px;
  }
}

@media screen and (max-width: 550px) {

  .hero_content h1 {
    padding: 0px;
    font-size: 24px;
  }

  .signupFormContainer>*:last-child,
  .heroContentContainer>*:first-child {
    margin-bottom: 0;
    margin-top: 0;
  }

  /* .hero_content h1 {
    font-size: 42px;
    margin-top: 12px;
    font-weight: 500;
  } */

  .hero_content h2 {
    font-size: 16px;
  }

  .signup2,
  .signup {
    width: 100%;
    padding: 12px;
    border-radius: 0px;
  }

  .heroContentContainer,
  .signupFormContainer {
    padding-bottom: 0px;
    width: 100%;
    flex: none;
  }
}
.lst-kix_646qxncye0q7-7>li:before {
    content: "\0025cb   "
}

.lst-kix_646qxncye0q7-8>li:before {
    content: "\0025a0   "
}

ul.lst-kix_t56a51rxgwuq-8 {
    list-style-type: none
}

ul.lst-kix_t56a51rxgwuq-7 {
    list-style-type: none
}

ul.lst-kix_sa88lnacwndx-3 {
    list-style-type: none
}

.lst-kix_646qxncye0q7-5>li:before {
    content: "\0025a0   "
}

.lst-kix_646qxncye0q7-6>li:before {
    content: "\0025cf   "
}

ul.lst-kix_sa88lnacwndx-2 {
    list-style-type: none
}

ul.lst-kix_sa88lnacwndx-1 {
    list-style-type: none
}

ul.lst-kix_sa88lnacwndx-0 {
    list-style-type: none
}

.lst-kix_sa88lnacwndx-2>li:before {
    content: "\0025a0   "
}

.lst-kix_sa88lnacwndx-0>li:before {
    content: "  "
}

.lst-kix_sa88lnacwndx-1>li:before {
    content: "\0025cb   "
}

.lst-kix_t56a51rxgwuq-1>li:before {
    content: "\0025cb   "
}

ul.lst-kix_646qxncye0q7-8 {
    list-style-type: none
}

.lst-kix_t56a51rxgwuq-0>li:before {
    content: "  "
}

.lst-kix_t56a51rxgwuq-2>li:before {
    content: "\0025a0   "
}

ul.lst-kix_646qxncye0q7-7 {
    list-style-type: none
}

ul.lst-kix_646qxncye0q7-6 {
    list-style-type: none
}

ul.lst-kix_646qxncye0q7-5 {
    list-style-type: none
}

.lst-kix_sa88lnacwndx-8>li:before {
    content: "\0025a0   "
}

ul.lst-kix_646qxncye0q7-4 {
    list-style-type: none
}

ul.lst-kix_646qxncye0q7-3 {
    list-style-type: none
}

ul.lst-kix_646qxncye0q7-2 {
    list-style-type: none
}

ul.lst-kix_646qxncye0q7-1 {
    list-style-type: none
}

ul.lst-kix_646qxncye0q7-0 {
    list-style-type: none
}

.lst-kix_t56a51rxgwuq-6>li:before {
    content: "\0025cf   "
}

.lst-kix_t56a51rxgwuq-7>li:before {
    content: "\0025cb   "
}

.lst-kix_646qxncye0q7-1>li:before {
    content: "\0025cb   "
}

.lst-kix_646qxncye0q7-2>li:before {
    content: "\0025a0   "
}

.lst-kix_sa88lnacwndx-3>li:before {
    content: "\0025cf   "
}

.lst-kix_646qxncye0q7-0>li:before {
    content: "  "
}

.lst-kix_646qxncye0q7-3>li:before {
    content: "\0025cf   "
}

.lst-kix_646qxncye0q7-4>li:before {
    content: "\0025cb   "
}

.lst-kix_sa88lnacwndx-4>li:before {
    content: "\0025cb   "
}

.lst-kix_sa88lnacwndx-5>li:before {
    content: "\0025a0   "
}

.lst-kix_t56a51rxgwuq-5>li:before {
    content: "\0025a0   "
}

.lst-kix_sa88lnacwndx-6>li:before {
    content: "\0025cf   "
}

.lst-kix_sa88lnacwndx-7>li:before {
    content: "\0025cb   "
}

.lst-kix_t56a51rxgwuq-4>li:before {
    content: "\0025cb   "
}

.lst-kix_t56a51rxgwuq-3>li:before {
    content: "\0025cf   "
}

.lst-kix_t56a51rxgwuq-8>li:before {
    content: "\0025a0   "
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

ul.lst-kix_sa88lnacwndx-8 {
    list-style-type: none
}

ul.lst-kix_sa88lnacwndx-7 {
    list-style-type: none
}

ul.lst-kix_sa88lnacwndx-6 {
    list-style-type: none
}

ul.lst-kix_sa88lnacwndx-5 {
    list-style-type: none
}

ul.lst-kix_sa88lnacwndx-4 {
    list-style-type: none
}

ul.lst-kix_t56a51rxgwuq-6 {
    list-style-type: none
}

ul.lst-kix_t56a51rxgwuq-5 {
    list-style-type: none
}

ul.lst-kix_t56a51rxgwuq-4 {
    list-style-type: none
}

ul.lst-kix_t56a51rxgwuq-3 {
    list-style-type: none
}

ul.lst-kix_t56a51rxgwuq-2 {
    list-style-type: none
}

ul.lst-kix_t56a51rxgwuq-1 {
    list-style-type: none
}

ul.lst-kix_t56a51rxgwuq-0 {
    list-style-type: none
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.d8 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.d0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;
    font-family: "Verdana";
    font-style: normal
}

.d12 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

.d9 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Verdana";
    font-style: normal
}

.d2 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10.5pt;
    font-family: "Verdana";
    font-style: normal
}

.d6 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;
    font-family: "Verdana";
    font-style: italic
}

.d3 {
    padding-top: 15pt;
    padding-bottom: 4pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.d11 {
    padding-top: 24pt;
    padding-bottom: 6pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: center
}

.d1 {
    padding-top: 9pt;
    padding-bottom: 9pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
}

.d5 {
    -webkit-text-decoration-skip: none;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    font-size: 9pt;
    font-family: "Verdana"
}

.d10 {
    background-color: #ffffff;
    max-width: 100%;
    padding: 12pt 72pt 72pt 72pt
}

.d4 {
    margin-left: 36pt;
    padding-left: 0pt
}

.d7 {
    padding: 0;
    margin: 0
}

.d10 title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.d10 subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.d10 li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

.d10 p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial"
}

.d10 h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.d10 h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.d10 h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.d10 h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.d10 h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.d10 h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}
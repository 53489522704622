@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow-x: hidden !important;
    font-family: 'Poppins', sans-serif;
}

@layer components {
    .accordion-header {
        @apply py-4 px-6 cursor-pointer
    }

    .accordion-body {
        @apply max-h-0 overflow-hidden transition-all duration-200 ease-out
    }
}
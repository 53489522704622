.aboutWrapper {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.aboutTop,
.aboutBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 120px;
}

.aboutTop .aboutLeft h2,
.aboutBottom .aboutLeft h2,
.aboutTop .aboutRight h2,
.aboutBottom .aboutRight h2,
.aboutTop .aboutLeft p,
.aboutBottom .aboutLeft p,
.aboutTop .aboutRight p,
.aboutBottom .aboutRight p {
    text-align: left;
}

.aboutBottom>.aboutRight,
.aboutBottom>.aboutLeft,
.aboutTop>.aboutRight,
.aboutTop>.aboutLeft {
    flex: 1;
}

.checkmark-list {
    list-style-type: none;
    padding-left: 0;
    margin-left: 0;
}

.checkmark-list li {
    padding-left: 30px;
    background-image: url('../../assets/radio-selected.svg');
    background-repeat: no-repeat;
    background-position: 0 center; 
    background-size: 20px 20px;
    margin-bottom: 10px;
}

.aboutTop h2,
.aboutBottom h2 {
    font-weight: 500;
    margin-bottom: 24px;
    font-size: 30px;
    color: #1D3557;
    text-align: center;
}

.aboutTop p,
.aboutBottom p {
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 32px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1280px) {
    .aboutWrapper {
        width: 95%;
        padding: 40px 30px;
    }

    .aboutLeft h2,
    .aboutRight h2 {
        font-size: 18px;
    }

    .aboutLeft p,
    .aboutRight p {
        font-size: 12px;
        line-height: 22px;
    }

    .aboutWrapper {
        gap: 20px;
    }

    .aboutTop,
    .aboutBottom {
        gap: 40px;
    }
}

@media screen and (max-width: 600px) {

    .aboutWrapper {
        width: 95%;
        padding: 10px 0;
    }

    .aboutLeft h2,
    .aboutRight h2 {
        font-size: 18px;
    }

    .aboutLeft p,
    .aboutRight p {
        font-size: 15px;
        line-height: 22px;
    }

    .aboutWrapper {
        gap: 20px;
    }

    .aboutTop .aboutRight,
    .aboutBottom .aboutLeft {
        display: none;
    }

}
.ftop {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 120px;
  }
  
  .ftop .logo {
    width: 210px;
  }
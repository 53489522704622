.ftop {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 120px;
}

.ftop .logo {
  width: 210px;
}

.footerCopywrite {
  height: 90px;
  background: #5D9CB8;
  text-align: center;
  padding: 10px 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  border-top: 1px solid #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}